$modal: '.modal';

.product-zoom-popup {

  &__inner {
    display: flex;
    align-items: center;
    padding: 28px 0;
    min-height: 0; }

  &__thumbs {
    width: 60px;
    flex-shrink: 0;
    margin-right: 85px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .swiper-container {
      max-height: 100%; } }

  &__photo-large {
    position: relative;
    width: 100%;
    max-width: calc(100% - 145px);
    height: 100%;

    .swiper-container {
      height: 100%; } }

  &__main-image {
    max-height: calc(100vh - 100px);
    margin-left: auto;
    height: 100%; }

  &__slide {
    img {
      display: block;
      margin: 0 auto;
      max-height: 100%; } }

  &__video-wrap {
    position: relative;
    max-width: 90%;
    padding: 0;
    height: 100%;
    margin: 0 auto;
    flex: 1;
    overflow: hidden;
    background-color: $G-3;
    @include xs {
      max-width: 100%; }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto; } }


  #{$modal}__close {
    top: 24px;
    right: 24px; }

  #{$modal}__content {
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    padding: 40px 32px;
    display: flex;
    flex-direction: column; }


  &__thumbs-prev,
  &__thumbs-next {
    width: 40px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 50%;

    &.is-hidden {
      display: none; }

    span {
      display: block;
      width: 18px;
      height: 18px;
      border-right: 2px solid $G-5;
      border-bottom: 2px solid $G-5; } }

  &__thumbs-prev {
    margin: 0 auto;
    span {
      transform: rotate(-135deg); } }

  &__thumbs-next {
    margin: 4px auto 0;

    span {
      transform: rotate(45deg);
      margin-top: -10px; } }


  .swiper-slide {
    display: flex;
    align-items: center; } }


.product-zoom-popup-large {
  position: relative;

  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    z-index: 3;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: -15px;

    &.is-hidden {
      display: none; }

    span {
      display: block;
      width: 22px;
      height: 22px;
      border-right: 2px solid $G-5;
      border-bottom: 2px solid $G-5; } }

  &__prev {
    left: 0;

    span {
      transform: rotate(135deg);
      margin-left: 7px; } }

  &__next {
    right: 0;

    span {
      transform: rotate(-45deg);
      margin-right: 7px; } } }
