.product-label {
  //position: absolute
  //top: 28px
  //left: 28px
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 7px 11px;
  line-height: 1;
  color: #ffffff;
  @include bold;
  font-size: 13px;
  border-radius: 14px;

  &.is-discount {
    background-color: #D01000; }

  &.is-new {
    background-color: #31945E; }

  //+sm
  //  top: 11px
 }  //  left: 19px
