.one-click-buy {
  $this: &;

  display: flex;
  align-items: center;
  z-index: 1;

  &.is-active {
    #{$this}__left,
    #{$this}__right {
      transform: translate(0, 0); }

    #{$this}__right {
      opacity: 0;
      pointer-events: none; }

    #{$this}__left {
      opacity: 1;
      pointer-events: auto; } }

  &__left,
  &__right {
    width: 100%;
    flex-shrink: 0;
    @include xsUp {
      transform: translateX(-100%); } }

  &__left {
    display: flex;
    align-items: center;
    z-index: 1;
    @include xsUp {
      opacity: 0;
      pointer-events: none; } }

  &__right {
    display: flex;
    align-items: center;
    @include xs {
      display: none; } }

  &__form {
    display: flex;
    align-items: center; }

  &__link {
    margin-left: 20px; }

  &__link-inner {
    display: initial;
    font-size: 13px;
    color: #000000;
    border-bottom: 1px dashed rgba(68, 68, 68, 0.5);
    cursor: pointer;

    &:hover {
      border-color: $red;
      color: $red; } }

  &__action {
    z-index: 2;

    .button {
      width: 152px;
      padding: 16px 12px;
      font-size: 15px;
      @include semi;
      @include xs {
        font-size: 13px;
        padding: 10px 12px;
        color: #000000;
        background-color: #DCDCDC;
        &:hover {
          background-color: $red;
          color: #ffffff; } } } }

  &__field {
    position: relative;
    right: -1px;

    .field {
      &__input {
        border: 1px solid #888888;
        border-radius: 2px 0 0 2px;
        padding: 17px 12px;
        min-height: 54px;
        @include xs {
          min-height: 40px;
          padding: 9px 12px 11px;
          border-color: #dcdcdc;
          font-size: 14px; } } }

    .tooltip {
      min-width: 200px; } } }
