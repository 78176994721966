.product-media {
  @include xs {
    margin-top: 24px; }

  &__title {
    font-size: 28px;
    margin-bottom: 28px;
    font-weight: normal;
    @include sm {
      display: none; } }

  &__content {
    font-size: 0; }

  &__video {
    max-width: 700px;
    margin: 0 auto;

    &-wrap {
      position: relative;
      width: 100%;
      padding: 32px;
      margin: 20px;
      text-align: center;
      background-color: #F7F7F7;
      @include xs {
        margin: 0 0 28px;
        padding: 0;
        background-color: transparent; } }

    &-inner {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden; }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 100%;
      max-width: 700px;
      margin: 0 auto; } }

  img {
    display: inline-block;
    max-width: calc(50% - 20px);
    margin: 10px 10px 20px;
    @include xs {
      width: 100%;
      max-width: none;
      margin: 0 0 28px; }

    &:last-child {
      @include xs {
        margin-bottom: 0; } } } }
