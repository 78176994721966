.color-picker {
  $this: &;

  &__name {
    margin-bottom: 7px;
    font-size: 13px; }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -10px;
    min-width: 0;
    @include sm {
      display: block;
      font-size: 0; } }

  &__item-wrap {
    display: flex;
    align-items: center;
    justify-content: center; }

  &__item {
    position: relative;
    width: 41px;
    height: 34px;
    flex-shrink: 0;
    min-width: 0;
    margin: 10px 2px 0;
    background-color: transparent;
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    @include sm {
      display: inline-block;
      vertical-align: middle; }

    &--has-border {
      #{$this}__inner {
        border: 2px solid #DCDCDC; } }


    &.is-active {
      #{$this}__inner {
        border-color: $G-5; } }

    &.is-disabled {
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 1px;
        transform: rotate(135deg);
        background-color: #000000; } } }

  &__inner {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    padding: 2px;
    transform: rotate(22.25deg);
    border: 1px solid transparent;
    border-radius: 100%; }

  &__item-bg {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%; }

  &__legend {
    margin-left: 5px;
    font-size: 15px; }

  &__tooltip {
    .tooltip {
      white-space: nowrap; } } }
