.product-tabs {
  display: flex;
  justify-content: flex-start;
  //justify-content: space-between
  border-top: 1px solid #DCDCDC;
  overflow-x: auto; }


.product-tab {
  $tab: &;

  position: relative;
  display: flex;
  align-items: baseline;
  padding: 17px 15px;
  font-size: 18px;
  @include semi;
  cursor: pointer;
  white-space: nowrap;

  @include md {
    font-size: 15px;
    padding: 10px 12px; }

  &:first-child {
    padding-left: 9px; }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    transform: scaleY(0);
    background-color: $red;
    transition: transform .15s ease; }

  &:hover {
    color: $red;

    #{$tab}__title {
      border-color: $red; }

    &:before {
      transform: scaleY(1); } }

  &__amount {
    font-size: 12px;
    margin-left: 6px; }


  &.is-active {
    color: $red;

    #{$tab}__title {
      border-color: transparent; }

    &:before {
      transform: scaleY(1); } }

  &__title {
    line-height: 1.1;
    border-bottom: 1px dashed rgba(68, 68, 68, 0.5); } }


.product-tab-content {
  $content: &;
  margin-top: 15px;

  &__item {
    @include sm {
      &:first-child {
        #{$content}__head {
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 20px;
            width: calc(100% - 40px);
            border-top: 1px solid #e0e0e0; }
          &.is-active {
            &:after {
              display: none; } } } } }
    @include smUp {
      display: none;

      &.is-active {
        display: block;
        margin-bottom: 28px; } } }

  &__item-inner {
    display: none;
    @include smUp {
      display: block !important; } }

  &__divider {
    display: block;
    width: 100%;
    max-width: 720px;
    margin: 30px 0 0;
    border: 0;
    border-bottom: 1px solid #dcdcdc;
    background-color: transparent; }

  &__head-title {
    font-size: 16px;
    @include semi; }

  &__head-btn {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      transform: translateY(-50%);
      background-color: #000000;
      transition: transform .3s ease, background-color .3s ease; }

    &:after {
      transform: translateY(-50%) rotate(90deg); } }


  &__head {
    display: none;
    @include sm {
      display: block;
      position: relative;
      padding: 11px 20px;
      margin-left: -20px;
      margin-right: -20px;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 20px;
        width: calc(100% - 40px);
        border-bottom: 1px solid #e0e0e0; }

      &.is-active {
        color: #ffffff;
        background-color: $red;

        &:before {
          display: none; }

        .product-tab-content__head-btn {
          &:before,
          &:after {
            background-color: #ffffff;
            transform: translateY(-50%) rotate(-180deg); } } } } } }
