.characteristics-table {
  font-size: 15px;
  @include sm {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
  @include r(400) {
    table-layout: fixed; }

  &__hint {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 2px;
    margin-left: 3px;
    @include sm {
      display: none; } }

  &__tooltip-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    text-decoration: none;
    width: 40px; }

  tr {
    &:nth-child(even) {
      @include sm {
        background-color: #F7F7F7; } } }

  td {
    padding: 5px 30px 5px 0;
    @include sm {
      padding: 7px 20px 8px 20px; }
    @include xs {
      overflow: hidden;
      word-wrap: break-word;
      @include prevent-text; }
    @include r(350) {
      padding: 7px 16px 8px 16px; }


    &:first-child {
      padding-right: 40px;
      @include sm {
        padding-right: 30px; } }

    &:last-child {
      max-width: 355px;

      span {
        text-decoration: underline; }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none; } } }

    span,
    a {
      color: #000000;
      @include xs {
        //hyphens: auto
        @include prevent-text;
        display: inline-block; }

      &.tooltip-trigger {
        @include xs {
          display: none; } } }

    .tooltip {
      min-width: 240px;

      span {
        text-decoration: none; } } } }
