.product-delivery {
  max-width: 820px;
  @include sm {
    margin-top: -8px; }

  &__title {
    margin-bottom: 28px;
    font-size: 28px;
    font-weight: normal;
    @include sm {
      display: none; } }

  &__subtitle {
    font-size: 22px;
    text-decoration: none;
    color: #000000;
    line-height: 1;

    span {
      border-bottom: 1px solid rgba(68, 68, 68, 0.5); } }

  &__content {
    margin-top: 18px;
    @include sm {
      margin-top: 24px; } }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 18px;
    @include sm {
      display: block; } }

  &__item {
    width: calc(100% / 3);
    @include sm {
      width: 100%; }

    &:not(:last-child) {
      @include sm {
        margin-bottom: 22px; }
      @include smUp {
        padding-right: 32px; } } } }
