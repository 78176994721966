.simple-description {
  display: flex;

  &__icon {
    font-size: 40px;
    margin-right: 16px;
    @include sm {
      width: 40px;
      height: 0;
      flex-shrink: 0; }

    .icon {
      fill: $G-6;

      &-house {
        font-size: 37px;
        @include sm {
          font-size: 40px; } }

      &-van {
        font-size: 25px;
        @include sm {
          margin-bottom: 10px; } }

      &-card {
        font-size: 32px; }

      &-courier {
        @include sm {
          margin-left: 3px; } }

      &-courier {
        @include sm {
          margin-left: 4px; } } } }

  &__title {
    font-size: 15px;
    @include semi; }

  &__text {
    font-size: 13px;
    margin-top: 4px;

    strong {
      color: $red;
      @include semi; } } }
