.product-info-row {

  &__vendor-code {
    font-size: 12px;
    color: $G-5;
    margin-left: 40px;
    margin-right: 10px;
    @include xs {
      margin-left: 24px; } }

  &__status {
    font-size: 12px; }

  &__rating {
    margin-left: auto; } }
