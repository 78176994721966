.product-characteristics {
  max-width: 640px;
  @include sm {
    max-width: none; }

  &__title {
    margin-bottom: 10px;
    line-height: 1;
    font-size: 28px;
    font-weight: normal;
    @include sm {
      display: none; } }

  &__subtitle {
    margin-bottom: 20px;
    @include sm {
      display: none; } } }
