.product-main {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    @include sm {
      margin-bottom: 22px; } }

  &__layout {
    //display: flex
    //justify-content: space-between
    //align-items: flex-start
    @include sm {
      display: block; } }

  &__inner {
    max-width: 1024px;
    @include clr; }

  &__assets {
    float: left;
    width: calc(100% - 520px);
    margin-right: 50px;
    @include sm {
      float: none;
      width: 100%;
      margin: 0 0 20px; } }

  &__actions {
    //width: 440px
    flex-shrink: 0;
    margin-top: -15px;
    @include sm {
      width: 100%;
      margin: 0; } }

  &__title {
    font-size: 28px;
    max-width: 900px;
    line-height: 1.3;
    @include sm {
      font-size: 20px; }
    @include xs {
      @include semi;
      line-height: 1.2; } }

  &__company {
    text-decoration: none;
    display: block;
    @include xs {
      display: none; }
    img {
      display: block;
      max-height: 44px; } }

  &__advantages {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 44px;
    margin-right: -12px;
    margin-bottom: -15px;
    @include sm {
      justify-content: flex-start;
      margin-left: -14px;
      margin-right: -14px;
      margin-top: 20px; }
    @include xxs {
      justify-content: space-around; } }

  &__advantages-item {
    max-width: 92px;
    margin: 0 12px 15px;
    @include xxs {
      max-width: none;
      width: calc(100%/2 - 24px); } } }
