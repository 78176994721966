.product-thumbs {
  $this: &;

  &__slide {
    border: 1px solid #DCDCDC;
    padding: 3px;
    width: 52px;
    height: 52px !important;
    //margin-bottom: 12px !important
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 1px solid transparent;
      pointer-events: none; }

    &.swiper-slide-thumb-active,
    &.is-active {
      border-color: $G-5;
      &:before {
        border-color: $G-5; } }

    &.is-video-btn {
      pointer-events: none;
      #{$this}__slide-inner {
        opacity: .5; } } }


  &__slide-inner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%; }

  &__slide-video {
    pointer-events: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    border: 2px solid $G-5;
    border-radius: 50%;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) translateX(2px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 0 7px 10px;
      border-color: transparent transparent transparent $G-5; }

    &:hover {
      &:before {
        transform: translate(-50%, -50%) translateX(2px) scale(1.1); } } }

  .swiper-wrapper {
    //max-height: 348px
    //max-height: 288px
    max-height: calc((60px * 4) + (9px * 4)); } }
