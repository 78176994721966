.product-assets {
  display: flex;
  align-items: flex-start;
  @include xs {
    display: block; }

  &__photo-large {
    position: relative;
    width: 100%;
    min-width: 0;
    img {
      display: block;
      margin: 0 auto; } }

  &__main-image {
    img {
      max-width: 100%; }

    .swiper-container {
      @include sm {
        padding-bottom: 20px; } }

    .swiper-scrollbar {
      height: 3px;
      @include smUp {
        display: none; } }

    .swiper-scrollbar-drag {
      background: $G-5; } }

  &__label {
    margin-left: 13px;
    @include sm {
      margin: 0; } }

  &__image-label {
    position: absolute;
    top: 0;
    right: -30px;
    z-index: 2;
    @include sm {
      right: 0; }
    img {
      max-height: 56px;
      @include xs {
        max-width: 100px; } } }

  &__thumbs {
    position: relative;
    width: 60px;
    flex-shrink: 0;
    margin-right: 35px;
    @include md {
      display: none; } }

  &__thumbs-prev,
  &__thumbs-next {
    width: 40px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 50%;

    &.is-hidden {
      display: none; }

    span {
      display: block;
      width: 18px;
      height: 18px;
      border-right: 2px solid $G-5;
      border-bottom: 2px solid $G-5; } }

  &__thumbs-prev {
    margin: 0 auto;
    span {
      transform: rotate(-135deg); } }

  &__thumbs-next {
    margin: 4px auto 0;

    span {
      transform: rotate(45deg);
      margin-top: -10px; } }

  &__gallery-btn {
    margin-top: 8px;
    text-align: center;
    font-size: 13px;
    @include semi;
    color: $G-5;
    cursor: pointer;
    span {
      border-bottom: 1px dashed rgba(68, 68, 68, 0.5); }
    &:hover {
      color: $red;
      span {
        border-bottom: 1px dashed rgba(198, 67, 68, 0.5); } } }

  &__info-row {
    @include xs {
      margin-bottom: 16px; } } }


.zoomWindow {
  top: -27px !important;
  margin-left: 50px;
  min-width: 585px;
  min-height: 430px;
  border: 2px solid #888888 !important;
  @include r(1100) {
    margin-left: 0;
    min-width: 410px;
    min-height: 380px; } }
