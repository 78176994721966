.product-actions {
  $this: &;

  max-width: 440px;
  margin-left: auto;
  @include sm {
    max-width: none; }

  &__row {
    display: flex;
    padding: 10px 0;
    @include xs {
      padding: 7px 0; }
    &:not(:last-child) {
      border-bottom: 1px solid #DCDCDC; }

    &--border {
      border-bottom: 1px solid #DCDCDC; }

    &--center {
      align-items: center; }

    &--column {
      flex-direction: column; }

    &--between {
      justify-content: space-between; }

    &--wrap {
      flex-wrap: wrap; }

    &--pb-reset {
      padding-bottom: 0; }

    &.is-desktop {
      @include xs {
        display: none; } } }

  &__top-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    @include sm {
      margin-bottom: 18px; }

    &--details {
      align-items: flex-start;
      margin-bottom: -20px;
      @include xs {
        display: block;
        margin-bottom: 0; } } }

  &__title {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 6px; } }

  &__title-inner {
    font-size: 13px;
    @include bold; }

  &__title-icon {
    cursor: pointer;
    .icon {
      display: block; }
    &:hover {
      .icon {
        fill: $red; } } }

  &__tooltip-content {
    .tooltip {
      max-width: 320px; } }

  &__note {
    padding-left: 5px;
    font-size: 12px;
    color: #888888;
    &.is-desktop {
      @include xs {
        display: none; } }
    &.is-mobile {
      margin-top: 8px;
      @include xsUp {
        display: none; } } }

  &__tinting {
    width: 100%;
    #{$this}__top-row {
      @include xs {
        display: block;
        margin-bottom: 6px; } }
    #{$this}__note {
      @include xs {
        padding: 0; } } }

  &__tinting-link {
    display: inline;
    font-size: 15px;
    color: #000000;
    border-bottom: 1px solid rgba(68, 68, 68, 0.5);
    cursor: pointer;

    &:hover {
      border-color: $red;
      color: $red; } }

  &__capacity-link {
    margin-left: auto; }

  &__capacity-link-inner {
    font-size: 12px;
    color: #000000;
    display: inline;
    cursor: pointer;
    border-bottom: 1px dashed rgba(68, 68, 68, 0.5);

    &:hover {
      border-color: $red;
      color: $red; } }

  &__capacity {
    //max-width: 280px
    margin-left: -4px;
    margin-top: -4px;
    font-size: 0;

    .product-tag {
      margin: 4px; } }

  &__buy {
    margin: 16px 0 11px; }

  &__cost {
    display: flex;
    align-items: flex-end; }

  &__main-cost {
    line-height: 1;
    letter-spacing: 0.2px;
    @include bold;

    span {
      margin-right: 4px;
      font-size: 32px; }

    &.is-discount {
      color: #C64344; } }

  &__old-cost {
    margin-left: 13px;
    text-decoration: line-through;
    line-height: 1.4;
    font-size: 13px; }

  &__buy-action {
    display: flex;
    align-items: center;
    @include xs {
      flex-wrap: wrap; }

    &:not(:first-child) {
      margin-top: 13px; } }

  &__counter {
    flex-shrink: 0;
    margin-right: 20px; }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 15px; }

  &__secondary-btn {
    &:not(:last-child) {
      margin-right: 35px; }

    .user-notification {
      &__text {
        line-height: 1;
        margin-top: 3px;
        border-bottom: 1px dashed rgba(68, 68, 68, 0.5); }
      &__icon {
        margin-right: 14px; }

      &.is-active {
        .user-notification__icon {
          margin-right: 19px; }
        .user-notification__text {
          border-bottom: 1px solid rgba(68, 68, 68, 0.5); } }

      &:hover {
        .user-notification__text {
          border-color: $red; } } } }

  &__coding {
    margin: 10px 0;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 38px; }

    .tooltip {
      max-width: none; } }

  &__coding-inner {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      display: block;

      &:hover {
        fill: $red; } } }

  &__coding-title {
    position: relative;
    margin-right: 6px;
    border-bottom: 1px dashed rgba(68, 68, 68, 0.5);
    line-height: 1.2;
    font-size: 13px;
    @include bold;
    color: #000000;
    &:hover {
      border-color: $red;
      color: $red; } }

  &__status-label {
    position: relative;
    margin-top: 30px;
    border-right: 0;
    padding: 14px 20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    line-height: 1.55em;
    width: calc(100% - 15px);
    @include xs {
      margin-top: 0;
      font-size: 14px; }

    a {
      text-decoration: none;
      color: currentColor;
      border-bottom: 1px solid rgba(68, 68, 68, 0.5);
      &:hover {
        border-color: $red;
        color: $red; } } }

  &__one-click {
    @include xs {
      margin-top: 16px; } }

  &__main-buy-btn {
    @include xs {
      flex-grow: 1;
      .button {
        width: 100%;
        max-width: 220px;
        padding: 14px 16px; } } }

  &__change-color {
    margin-top: 10px; }

  &__counterpart {
    margin-top: 28px; }

  &__details {
    margin-top: 6px;
    font-size: 13px;
    color: $G-5;
    @include xs {
      margin-top: 3px; }

    &:last-child {
      padding-right: 20px; }

    p + p {
      margin-top: 3px; } }

  &__colors {
    margin-bottom: 10px;
    margin-right: auto; }

  &__gloss {
    margin-bottom: 10px; }

  &__dealer-label {
    margin-left: 12px; }

  &__retail {
    margin-top: 8px;
    font-size: 13px; } }
