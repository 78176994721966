.product-main-image {

  .swiper-wrapper {
    box-sizing: border-box;
    align-items: center; }

  img {
    max-width: 100%;
    margin: 0 auto;
    text-indent: -9999px; }

  &__slide {
    min-height: 200px;
    position: relative; }

  &__video-wrap {
    position: relative;
    padding-bottom: 47.5%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    background-color: $G-3;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto; } } }
