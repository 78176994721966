.product-about {
  $this: &;
  max-width: 640px;
  @include sm {
    margin-top: 18px; }

  &__content {
    position: relative;
    max-height: 400px;
    overflow: hidden;
    @include xs {
      max-height: 835px; }

    &.is-small {
      &:before {
        display: none; } }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      z-index: 1;
      height: 50px;
      width: 100%;
      background: linear-gradient(0deg, #fff 10%, rgba(255, 255, 255, 0) 100%);
      transition: opacity .3s ease;
      pointer-events: none; }

    h2, h3, h4 {
      font-size: 16px;

      & + p,
      & + ul,
      & + ol {
        margin-top: 6px; } }

    p {
      font-size: 15px;
      line-height: 1.5;

      & + h2,
      & + h3,
      & + h4 {
        margin-top: 24px; }

      & + p {
        margin-top: 10px; } }

    ul, ol {
      & + p,
      & + h2,
      & + h3,
      & + h4 {
        margin-top: 24px; } }

    ul {
      li {
        padding-left: 20px;
        position: relative;
        font-size: 15px;

        & + li {
          margin-top: 4px; }

        &:before {
          content: '';
          position: absolute;
          top: 4px;
          left: 3px;
          width: 6px;
          height: 10px;
          border-bottom: 2px solid #138547;
          border-right: 2px solid #138547;
          transform: rotate(45deg); } } }

    ol {
      list-style: decimal inside;

      li {
        font-size: 15px;

        & + li {
          margin-top: 4px; } } }

    a {
      @include semi;
      @include common-link; } }

  &__more {
    margin-top: 12px;

    .read-more {
      &__toggle {
        line-height: 1;
        @include semi; } } }


  &.is-expanded {
    #{$this}__content {
      max-height: none;

      &:before {
        display: none; } } } }
