.color-codes {
  $this: &;

  width: 100%;

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:hover {
      #{$this}__add {
        opacity: 1; } }

    & + & {
      margin-top: 4px; }

    &:first-child {
      .action-buttons__inner {
        display: none; } } }

  &__actions {
    margin-top: 5px; }

  &__field {
    display: flex;
    align-items: center;

    .field__input {
      flex-shrink: 0;
      padding: 10px 12px;
      width: 142px;
      @include font-default;
      border-color: #888888;
      @include xs {
        width: 120px;
        padding: 9px;
        font-size: 13px; } } }

  &__add {
    position: relative;
    height: 14px;
    width: 14px;
    flex-shrink: 0;
    margin-left: 20px;
    cursor: pointer;
    background-color: transparent;
    opacity: 0;
    transition: opacity $easing-default;
    @include xs {
      margin-left: 10px;
      opacity: 1; }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $G-5;
      transition: transform $easing-default; }

    &:before {
      transform: translateY(-50%); }
    &:after {
      transform: translateY(-50%) rotate(90deg); }

    &:hover {
      &:before {
        transform: translateY(-50%) rotate(90deg); }
      &:after {
        transform: translateY(-50%) rotate(180deg); } } }


  &__catalog {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 4px;
    font-size: 13px;
    color: $G-5; }

  &__subtitle {
    margin: 4px 8px 4px 0; }

  &__cat-links {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }

  &__cat-link {
    margin-top: 2px;
    margin-bottom: 10px;
    position: relative;
    top: 5px;
    color: #000000;
    border-bottom: 1px solid rgba(68, 68, 68, 0.5);
    transition: color $easing-default;
    text-decoration: none;
    line-height: 1;
    text-transform: uppercase;

    &:not(:last-child) {
      margin-right: 10px; }

    &:hover {
      color: $red;
      border-color: rgba(198, 67, 68, 0.5); } } }

