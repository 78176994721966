.product-docs {

  &__title {
    font-size: 28px;
    margin-bottom: 28px;
    font-weight: normal;
    @include sm {
      display: none; } }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
    @include sm {
      display: block;
      padding: 17px 0 34px; } }

  &__item {
    @include smUp {
      max-width: 100px;
      margin-bottom: 30px;

      &:not(:last-child) {
        margin-right: 60px; } }

    @include sm {
      &:not(:last-child) {
        margin-bottom: 24px; } } } }
