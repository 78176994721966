.doc-link {
  $this: &;

  text-align: center;
  text-decoration: none;
  color: #000000;
  @include sm {
    text-align: left;
    display: flex;
    align-items: center; }


  &:hover {
    color: $red;

    #{$this}__row {
      color: $red; }

    #{$this}__title {
      span {
        border-bottom: 1px solid rgba(198, 67, 68, 0.5); } }

    .icon {
      fill: $red; } }

  &__title {
    font-size: 14px;
    @include semi;
    line-height: 1.4;
    display: block;
    @include sm {
      margin-right: 20px; }

    span {
      border-bottom: 1px solid rgba(68, 68, 68, 0.5); } }

  &__text {
    font-size: 13px; }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    color: $G-5;
    @include sm {
      margin-top: 0; }

    .icon {
      display: block;
      margin-right: 8px;
      font-size: 20px; } } }
