.header-product {

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__info {
    display: flex;
    align-items: center;
    padding-right: 10px; }

  &__actions {
    display: flex;
    align-items: center; }

  &__preview {
    max-width: 48px;
    margin-right: 20px; }

  &__title {
    font-size: 15px;
    @include semi;
    max-width: 532px;
    @include r(1120) {
      max-width: 400px; }

    &.is-small {
      max-width: 300px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      @include xl {
        max-width: 180px; } } }

  &__rating {
    margin-left: 25px; }

  &__buy {
    margin-right: 28px; }

  &__cost {
    display: flex;
    align-items: flex-end;
    margin-right: 20px; }

  &__main-cost {
    line-height: 1;
    @include semi;

    span {
      font-size: 18px; }

    &.is-discount {
      color: #C64344; } }

  &__old-cost {
    margin-left: 10px;
    text-decoration: line-through;
    font-size: 13px;
    line-height: 0.9; }

  &__buttons {
    display: flex;
    align-items: center;
    .user-notification__icon {
      margin-right: 20px; } }

  &__dealer {
    margin-right: 20px; }

  &__retail {
    margin-right: 20px;
    font-size: 13px; } }

