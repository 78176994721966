.product-recommendations {
  max-width: 665px;
  @include sm {
    padding-top: 10px; }

  &__title {
    font-size: 28px;
    margin-bottom: 16px;
    font-weight: normal;
    @include xs {
      display: none; } }

  &__head {
    position: relative;
    cursor: pointer;
    font-size: 15px;
    @include semi;
    padding: 12px 26px;
    @include sm {
      padding: 8px 26px; }

    &:before {
      content: '';
      position: absolute;
      left: 10px;
      top: 17px;
      width: 7px;
      height: 7px;
      border-bottom: 2px solid #000000;
      border-right: 2px solid #000000;
      transform: rotate(45deg);
      @include sm {
        top: 12px; } }

    &.is-active {
      @include smUp {
        background-color: #F9F9F9; }

      &:before {
        transform: rotate(45deg) scale(-1);
        top: 20px; } } }

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid #DCDCDC; } }

  &__content {
    display: none;
    padding: 0 26px 18px;
    font-size: 15px;
    @include sm {
      padding-top: 7px; }

    &.is-active {
      @include smUp {
        background-color: #F9F9F9; } }

    ol {
      li {
        position: relative;
        counter-increment: step-counter;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: counter(step-counter) '.'; } } }

    li {
      padding-left: 22px;
      @include sm {
        margin-left: -16px; } }

    li + li {
      margin-top: 14px; }

    a {
      @include semi;
      @include common-link; } } }
