.gloss-level {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: -14px;

  &__col {
    display: flex;
    min-width: 124px;
    margin-bottom: 14px;
    &:not(:last-child) {
      margin-right: 21px; } }

  &__icon {
    flex-shrink: 0;
    width: 15px;
    margin-right: 8px;
    margin-top: 2px;

    .icon {
      fill: $G-4;
      &-glass {
        margin-top: 4px; }
      &-matt {
        margin-top: 2px;
        font-size: 19px; } } }

  &__item {
    display: block;
    &:not(:last-child) {
      margin-bottom: 4px; } }

  &__text {
    font-size: 13px;
    a {
      display: inline-flex;
      padding: 2px 8px;
      border: 1px solid #CDCDCD;
      color: #000000;
      text-decoration: none;
      transition: color $easing-default, border-color $easing-default, background-color $easing-default;

      &:hover {
        background-color: $G-3;
        border-color: $G-3; }

      &.is-disabled {
        color: $G-4; }

      &.is-active {
        border-color: #000000; } } } }
