.product-status {

  &.is-available {
    color: #138547; }

  &.is-custom-made,
  &.is-expected {
    color: #B75C09; }

  &.is-not-available {
    color: #888888; }

  &.is-discontinue {
    @include semi;
    font-size: 15px; } }
