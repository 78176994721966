.product-advantage {
  text-align: center;

  &__text {
    font-size: 13px;
    color: $G-5;
    @include prevent-text; }

  &__icon {
    height: 40px; }

  .icon {
    font-size: 34px;
    display: block;
    max-width: 100%;
    margin: 0 auto;
    fill: $G-5;
    color: $G-5; } }
