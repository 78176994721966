$modal: '.modal';

.calculation-popup {

  &__title {
    margin-bottom: 12px; }

  &__description {
    font-size: 13px; }

  &__main {
    max-width: 254px;
    margin: 20px auto 0; }

  &__fields {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .calculation-field {
      max-width: 100px; } }

  &__sum {
    max-width: 122px;
    margin: 0 auto; }

  &__button {
    margin-top: 20px;
    margin-bottom: 12px;

    .button {
      padding: 10px 13px 12px; } }

  &__divider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
    font-size: 13px;
    color: #888888;
    font-style: italic;
    @include semi;

    span {
      margin: 0 7px; }

    &:before,
    &:after {
      content: '';
      width: 100%;
      flex-grow: 1;
      height: 1px;
      margin-top: 3px;
      background-color: #DCDCDC; } }

  &__result-content {
    margin-top: 24px;
    display: none; }

  &__result {
    @include semi;
    font-size: 15px;

    span {
      font-size: 18px; } }

  &__note {
    max-width: 480px;
    margin: 6px auto;
    font-size: 13px;
    color: $G-5;
    line-height: 1.6; }


  #{$modal}__close {
    top: 16px;
    right: 16px;
    font-size: 20px; }

  #{$modal}__content {
    width: 100%;
    max-width: 552px;
    margin: auto;
    padding: 46px 36px 24px;
    display: flex;
    flex-direction: column; } }


.calculation-field {
  width: 100%;
  margin-bottom: 14px;

  &__title {
    margin-bottom: 6px;
    font-size: 15px; }

  &__label {
    position: relative; }

  &__input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #888888;
    border-radius: 2px;
    height: 44px;
    padding: 14px 30px 14px 14px;
    color: #000000;
    font-size: 15px;

    &:focus {
      border-color: #000000; } }

  &__unit {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    @include semi;
    color: #888888;
    background-color: #ffffff;
    padding-left: 5px; }

  &__splitter {
    position: relative;
    align-self: center;
    margin-top: 12px;
    width: 16px;
    height: 16px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: $G-5; }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg); }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg); } } }
