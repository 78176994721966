.product-counterpart {
  $this: &;

  &__text {
    font-size: 15px; }

  &__image {
    display: block;
    max-width: 80px;
    flex-shrink: 0;
    margin-right: 12px; }

  &__item {
    margin-top: 20px;
    display: flex;
    text-decoration: none;
    color: #000000;

    &:hover {
      #{$this}__title {
        color: $red; } } }

  &__title {
    display: block;
    margin-bottom: 6px;
    font-size: 15px; }

  &__cost {
    font-size: 18px;
    color: $red;
    @include semi; }

  &__old-cost {
    margin-left: 8px;
    text-decoration: line-through;
    font-size: 13px;
    color: #000000;
    font-weight: normal; } }
