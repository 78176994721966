.product-bar-buy {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 7px 20px;
  background-color: $G-0;
  @include xsUp {
    display: none; }

  &__image {
    max-width: 53px;
    padding-right: 5px;
    margin-right: auto;
    flex-shrink: 0;

    img {
      display: block;
      max-width: 100%; } }

  &__prices {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end; }

  &__cost {
    font-size: 14px;
    color: #000000;
    @include semi;
    white-space: nowrap;

    span {
      font-size: 18px;
      @include bold; }

    &.is-discount {
      color: $red; } }

  &__discount {
    margin-left: 8px;
    margin-bottom: -3px;
    white-space: nowrap;
    font-size: 13px;
    color: #000000;
    text-decoration: line-through; }

  &__btn {
    margin-left: 12px;

    .button {
      width: 90px;
      height: 42px;
      padding: 9px 16px 11px; } }

  &__retail {
    margin-top: 2px;
    font-size: 13px; }

  &__dealer {
    margin-left: 8px; }

  &__row {
    display: flex;
    align-items: center; }


  &.is-dealer {
    padding: 9px 20px; } }
